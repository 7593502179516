<template>
  <div>
    <div class="dis align_center">
      <div class="icon"></div>
      <div class="service">供方市场管理</div>
    </div>

    <div class="flex">
      <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div class="flex marl_30 flex">
        <!-- <div
          style="
            width: 100px;
            line-height: 40px;
            position: relative;
            font-size: 14px;
          "
        >
          关键词搜索
        </div>
        <div class="flex">
          <input
            type="text"
            v-model="search"
            placeholder="请输入内容"
            style="
              width: 200px;
              height: 40px;
              border: 1px solid #cccc;
              border-radius: 5px;
              padding: 10px;
            "
          />
          <i
            @click="marketlist()"
            class="el-icon-search"
            style="font-size: 30px; line-height: 40px"
          ></i>
        </div> -->
        <div style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              ">
          关键词搜索
        </div>
        <div class="flex" style="border: 1px solid #cccc;">
          <input type="text" v-model="search" placeholder="请输入内容" style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                  border: none;
                  outline:none;
                " 
                 @keyup.enter="marketlist"/>
          <i @click="marketlist()" class="el-icon-search"
            style="font-size: 20px;margin-right: 20px; line-height: 40px"></i>
        </div>

        <div style="
          margin-left: 50px;
            width: 122px;
            height: 40px;
            background: #ff6618;
            border-radius: 6px;
            line-height: 40px;
            text-align: center;
          " @click="goadd()" class="fff">
          发布供给
        </div>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="id" label="ID" min-width="150"> </el-table-column>
      <el-table-column prop="contacts" label="行业类型" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="产品名称" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="规格型号" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="数量" min-width="150" align="center">
      </el-table-column>

      <el-table-column prop="price" label="单价" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="num" label="供货时限（天）" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="num" label="联系人" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="num" label="联系电话" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="num" label="发布时间" min-width="150" align="center">
      </el-table-column>
      <el-table-column prop="operation" label="操作" min-width="150" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="look(scope.row.id)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination 
    v-if="tableData.length != 0 "
    @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      allTableData: [],
      tableData: [],
      options: [
        { value: 1, label: "盐湖化工" },
        { value: 2, label: "新能源" },
        { value: 3, label: "新材料" },
        { value: 4, label: "冶金产业" },
        { value: 5, label: "绿色建材" },
        { value: 6, label: "装备制造" },
        { value: 7, label: "信息技术" },
        { value: 8, label: "节能环保" },
        { value: 9, label: "高原特色" },
        { value: 10, label: "其他" },
      ],
      search: "",
      total: "",
      ruleForm: {
        region: "",
      },
      drawDepos: { num: "" },
      currencymoney: "",
      setdrawDepos: {
        name: "",
        bank: "",
        count: "",
      },
      withdrawDepositlogin: false, //提现
      setwithdrawDepositlogin: false, //设置提现
    };
  },
  created() {
    this.marketlist();
  },
  methods: {
    look(item) {
      this.$router.push({ path: "/feedmessage", query: { id: item } });
    },
    // authentication() {
    //   let data = {
    //     token: localStorage.eleToken,
    //   };
    //   this.$get("/enterprise_user_status", data).then((res) => {
    //     console.log(res, "res");
    //     if (res.status === 1) {
    //       this.goadd();
    //     } else if (res.status === 2) {
    //       // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
    //       // location.href = "http://sso.smeqh.cn:9000/realname/index";
    //       // this.$message({
    //       //   type: "info",
    //       //   message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
    //       // });
    //     }
    //   });
    // },

    goadd() {
      this.$router.push("/feed");
    },
    // goadd() {
    //   this.$router.push("/releasefeed");
    // },
    // 获取供方列表
    marketlist(item) {
      let data = {
        token: localStorage.eleToken,
        keyword: this.search,
        industry_id: this.ruleForm.region,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      console.log(data, "data");
      this.$post("supply_lst", data).then((res) => {
        this.allTableData = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }

        //设置默认的分页数据
        if (this.allTableData.length != 0) {
          this.tableData = this.allTableData.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.tableData = [];
        }
        console.log(res, "res");
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
      this.sta = Number(tab.index) + 1;
      this.getorderlist();
      this.getcatelist();
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.marketlist(page);
      this.tableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
      this.marketlist();
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.tableData = tables;
      }
    },

  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.service {
  height: 30px;
  font-size: 24px;
  font-family: Source Han Sans SC Medium, Source Han Sans SC Medium-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 30px;
  margin-left: 10px;
}

::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}

::v-deep .el-form-item__content {
  margin-left: auto !important;
}

::v-deep .el-form-item__label {
  width: auto !important;
}
</style>
